import React from "react";
import first_visit from "../images/primaVisita.png";
import couple_visit from "../images/visitaCoppia.png";

export default function Service() {
  return (
    <div className="container">
      <h1 className="titleServices mt-5">Servizi e Consulenze</h1>

      <main>
        <div className="col-12">
          <img src={first_visit} alt="first_visit" className="media_style " />
        </div>
        <div className="row">
          <div className="firstSection">
            <h4 className="headerServices border-top mt-3">VISITA IN STUDIO</h4>
            <p className="contentServices">
              La prima visita nutrizionale è un incontro importante durante il
              quale viene elaborato un piano alimentare personalizzato in base
              alle esigenze del paziente. Durante la visita, verranno raccolte
              informazioni dettagliate sullo stato di salute della persona,
              comprese eventuali patologie pregresse o in corso, allergie o
              intolleranze alimentari, farmaci assunti, integratori, nonché
              eventuali trattamenti precedenti (chirurgici, dietetici, ecc.).
            </p>
            <h4 className="headerServices">Come si svolge la visita?</h4>
            <p className="contentServices">
              Durante la visita verrà effettuata una valutazione delle
              condizioni generali di salute: stile di vita, allergie, regolarità
              intestinale, regolarità del ciclo, eventuali disturbi etc.
              <br /> Verranno effettuate le{" "}
              <strong>misure antropometriche</strong> (misurazione della
              circonferenza, vita, fianchi,torace) e una
              <strong> bioimpedenziometria (BIA)</strong>, che permetterà di
              osservare lo stato di idratazione, la massa grassa e massa magra
              della persona.Sarà dedicato anche un momento all’esame delle
              abitudini alimentari attuali del paziente, inclusi orari e
              frequenza dei pasti, preferenze alimentari, eventuali abitudini
              scorrette (spuntini fuori orario, abbuffate, ecc.) e livelli di
              attività fisica. <br />
              <br />
              Insieme, si redigerà una bozza di piano alimentare personalizzato
              basato sulle informazioni raccolte, che tenga conto delle esigenze
              nutrizionali specifiche del paziente, delle sue preferenze e delle
              eventuali difficoltà nel seguire determinati consigli.
            </p>
            <h4 className="headerServices">
              Cosa occorre per la consulenza?
            </h4>{" "}
            <p className="contentServices">
              <ul>
                <li>Esami Ematochimici di routine</li>
                <li>Storia clinica (in caso di patologie accertate)</li>
              </ul>
            </p>
            <h4 className="headerServices">
              Cosa succede dopo la prima visita?
            </h4>
            <p className="contentServices">
              <ul>
                <li>
                  verrà consegnato via e-mail entro una settimana dalla visita
                  un piano alimentare PERSONALIZZATO;
                </li>

                <li>
                  verrà fissata la data di un appuntamento di controllo. In
                  genere avverrà dopo circa 3 settimane, anche se tale lasso
                  temporale può variare in base alle singole esigenze.
                </li>
              </ul>
            </p>{" "}
            <div className="call_to_action mb-3">
              <strong>
                Per prenotare o chiedere maggiori informazioni cliccare sul
                bottone in basso 👇
              </strong>
            </div>
            <button className="buttonServices mt-3">
              {" "}
              <a
                href="mailto:dottoressamartinabosso@gmail.com"
                className="linkServices"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Prenota una consulenza
              </a>
            </button>
          </div>

          <div className="secondSection">
            <h4 className="headerServices border-top mt-5">
              Piano alimentare di coppia 💕
            </h4>{" "}
            <div className="col-12">
              <img
                src={couple_visit}
                alt="couple_visit"
                className="media_style "
              />
            </div>
            <p className="contentServices">
              Una visita nutrizionale di coppia rappresenta un’opportunità per
              due persone, che siano partner, amici o familiari, di
              intraprendere insieme un percorso di miglioramento delle proprie
              abitudini. <br />
              L’obiettivo principale di questo tipo di visita è migliorare la
              salute alimentare e il benessere della coppia, affrontando le
              esigenze nutrizionali sia individuali che comuni attraverso una
              consulenza personalizzata. Durante una visita nutrizionale di
              coppia, l’obiettivo è aiutare entrambe le persone a raggiungere i
              propri obiettivi di salute, che possono essere comuni (come la
              perdita di peso, il miglioramento dell’alimentazione o il
              rafforzamento delle difese immunitarie) o individuali (ad esempio,
              una condizione medica che richiede una dieta particolare).
            </p>
            <h4 className="headerServices">Come si svolge la visita?</h4>
            <p className="contentServices">
              La visita inizia con una valutazione approfondita delle abitudini
              alimentari e dello stile di vita di entrambe le persone. Il
              nutrizionista esamina la dieta quotidiana, chiedendo ai pazienti
              di descrivere i pasti consumati durante la settimana, la frequenza
              dei pasti fuori casa, l’assunzione di snack e bevande, e altre
              abitudini legate all’alimentazione. Viene inoltre chiesto di
              condividere eventuali problematiche di salute preesistenti, come
              intolleranze alimentari, allergie, malattie metaboliche (diabete,
              colesterolo alto, ecc.) o disturbi alimentari. Questa fase di
              raccolta dei dati è fondamentale per personalizzare il piano
              nutrizionale e definire obiettivi realistici. <br />
              <br />
              <strong>
                Il passo successivo consiste nella creazione di un piano
                alimentare su misura.😉
              </strong>{" "}
              <br />
              <br />
              Questo piano non si limita a prescrivere semplicemente una lista
              di alimenti da consumare, ma include anche suggerimenti pratici
              per migliorare la qualità dell’alimentazione quotidiana. Ad
              esempio, verranno proposte ricette facili da preparare, spuntini
              salutari e indicazioni su come bilanciare correttamente i
              macronutrienti (proteine, carboidrati e grassi). Inoltre, verranno
              forniti consigli su come fare la spesa in modo intelligente,
              evitando cibi ad alta densità calorica e privilegiando alimenti
              freschi e naturali. Un altro aspetto fondamentale è l’aspetto
              pratico. Il nutrizionista può insegnare alla coppia come
              pianificare i pasti settimanali in anticipo, preparare il cibo
              insieme e sfruttare i momenti in cucina come opportunità di
              condivisione. Questi suggerimenti mirano a ridurre lo stress
              legato alla preparazione dei pasti e a promuovere una sana routine
              alimentare che non interferisca con gli impegni quotidiani.
            </p>
            <h4 className="headerServices">Vantaggi</h4>{" "}
            <p className="contentServices">
              Una visita nutrizionale di coppia offre numerosi vantaggi rispetto
              a una visita individuale. Il principale beneficio è la possibilità
              di lavorare insieme per raggiungere obiettivi comuni, il che
              favorisce l’impegno reciproco e facilita l’adozione di nuove
              abitudini alimentari. In un contesto di coppia, le difficoltà e le
              sfide che si affrontano nel percorso nutrizionale possono essere
              condivise, e il supporto reciproco diventa un fattore determinante
              per il successo. Inoltre, avere un piano nutrizionale che si
              adatta sia alle esigenze individuali che a quelle della coppia
              contribuisce a mantenere la coesione e la motivazione. In sintesi,
              la visita nutrizionale di coppia è una scelta ideale per chi
              desidera migliorare la propria alimentazione e salute in modo
              condiviso, affrontando insieme le sfide quotidiane e raggiungendo
              obiettivi di benessere a lungo termine.
            </p>
            <div className="call_to_action mb-3">
              <strong>
                Per prenotare o chiedere maggiori informazioni cliccare sul
                bottone in basso 👇
              </strong>
            </div>
            <button className="buttonServices mt-3">
              {" "}
              <a
                href="mailto:dottoressamartinabosso@gmail.com"
                className="linkServices"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Prenota una consulenza
              </a>
            </button>
          </div>

          <div className="secondSection">
            <h4 className="headerServices border-top mt-5">
              CONSULENZA ONLINE
            </h4>
            <p className="contentServices">
              {" "}
              Vista la situazione attuale del <strong>Covid-19</strong> e le
              misure adottate dal governo, è stata data la possibilità ai
              Biologi Nutrizionisti di effettuare le consulenze on- line.
              <br /> Il 5 novembre, alla luce della incredibile straordinarietà
              della situazione legata al grado di diffusione del{" "}
              <strong>Covid-19</strong>, il Presidente del Consiglio dell’Ordine
              Nazionale dei Biologi decreta di sospendere i divieti contenuti
              nel documento approvato con delibera n. 433 del 26 settembre 2019
              in tema di svolgimento on-line dell’attività professionale in
              campo nutrizionale, permettendoci quindi di effettuare le
              consulenze a distanza.
            </p>
            <h4 className="headerServices">
              Come si svolge la consulenza online?
            </h4>
            <p className="contentServices">
              La consulenza a distanza verra' effettuata tramite video chiamata.
              una volta confermata la prenotazione verrá condiviso il link per
              accedere al meeting. La seduta avra' una durata di circa un'ora.
              Durante la visita verrà effettuata una valutazione delle
              condizioni generali di salute: stile di vita, allergie, regolarità
              intestinale, regolarità del ciclo, eventuali disturbi etc. Le
              misure antropometriche verranno prese dal paziente con il mio
              aiuto.
            </p>
            <h4 className="headerServices">
              Cosa occorre per la consulenza online?
            </h4>{" "}
            <p className="contentServices">
              <ul>
                <li>Bilancia</li>
                <li>Metro</li>
                <li>Esami Ematochimici di routine</li>
                <li>Storia clinica (in caso di patologie accertate)</li>
              </ul>
            </p>
          </div>

          <div className="thirdSection">
            <h4 className="headerServices">Come si prenota?</h4>
            <p className="contentServices">
              La consulenza puo' essere prenotata attraverso e-mail tramite il
              bottone in basso. La prenotazione verra' confermata alla ricezione
              del pagamento.
            </p>
            <h4 className="headerServices">Come avviene il pagamento?</h4>
            <p className="contentServices">
              La consulenza deve essere prenotata via email e pagata in anticipo
              attraverso un bonifico o un sistema di pagamento rapido
              alternativo (PayPal, carta di credito). All’atto del pagamento
              verranno chiesti i dati necessari per l’inoltro della ricevuta
              fiscale.
            </p>
            <div className="call_to_action mb-3">
              <strong>
                Per prenotare o chiedere maggiori informazioni cliccare sul
                bottone in basso 👇
              </strong>
            </div>
            <button className="buttonServices mt-3">
              {" "}
              <a
                href="mailto:dottoressamartinabosso@gmail.com"
                className="linkServices mt-3"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Prenota una consulenza
              </a>
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}
